.mt-2 {
	margin: 4px 0 8px 0;
}
.css-qc6sy-singleValue {
	font-weight: 500;
}

.addres-identity-btn {
	position: fixed;
	bottom: 0;
	background-color: var(--white);
	width: 100%;
	max-width: var(--window-viewport);
	padding: 4px;
	.button__large {
		width: 100%;
		//opacity: 1;
		justify-content: center;
	}
}

.input-wrapper {
	&--form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		@media screen and (min-width: 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}
	&--col-1 {
		grid-column: span 2 / span 2;
	}
}
.input-wrapper-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	margin-bottom: 50px;
	.input {
		height: unset !important;
	}
	&--divider {
		text-align: center;
		border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
		width: 100%;
		line-height: 0.1rem;
		grid-column: span 2 / span 2;
		span {
			padding: 0px 20px;
			background: #ffffff;
			font-size: 15px;
			color: #747e99;
			font-weight: 500;
		}
	}
}

.kyc-address-details__header {
	margin-bottom: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
	&__title {
		font-weight: 600;
		font-size: var(--font-size-5);
		line-height: calc(var(--line-height-2) * 1.75);
		color: var(--color-text-90-light);
		letter-spacing: 0;
	}
	&__sub-title {
		font-weight: 400;
		font-size: var(--font-size-2);
		line-height: 16px;
		color: var(--color-text-70-light);
	}
}

.dropdown-wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;

	&__label {
		color: var(--color-text-70-light);
		font-size: var(--font-size-3);
		font-weight: 500;
		line-height: 16px;
		span {
			color: red;
			margin-left: 4px;
			font-size: 15px;
		}
	}
	.dropdown {
		.css-qc6sy-singleValue,
		.Select-placeholder {
			font-weight: 400 !important;
			font-size: var(--font-size-4) !important;
		}
		.css-1pndypt-Input,
		.css-vwja0k {
			font-weight: 400 !important;
			font-size: var(--font-size-4) !important;
		}
		.css-myp5cm-option,
		.css-12896ax-option {
			font-weight: 400 !important;
			font-size: var(--font-size-4) !important;
		}
	}
}
