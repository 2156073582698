.kyb-form {
	&__container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 100%;
		max-width: 770px;
		margin-bottom: 80px;
	}

	&__sub-header {
		&__wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;
			position: sticky;
			width: 100%;
			max-width: 770px;
			top: 0;
			left: 0;
			background-color: white;
			z-index: 1;
			padding-bottom: 16px;
		}

		&__title {
			color: var(--color-text-light-90);
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}

		&__description {
			color: var(--color-text-light-60);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
		}
	}

	&__drop-option {
		&__container,
		&__container-select {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			align-self: stretch;
			border-radius: 8px;
			border: 1px solid var(--color-border-light-100);
		}

		&__container-select {
			border: 1px solid var(--color-primary-light);
		}

		&__title-wrapper {
			display: flex;
			padding: 12px;
			align-items: center;
			gap: 8px;
			align-self: stretch;
			cursor: pointer;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&__lable-name {
			width: 100%;
			color: var(--color-text-light-90);
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		&__description-wrapper {
			display: flex;
			padding: 12px 12px 12px 28px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 8px;
			align-self: stretch;
			border-top: 1px solid var(--color-border-light-100);
		}

		&__description-name {
			color: var(--color-text-light-80);
			font-size: 14px;
			font-weight: 500;
			line-height: 30px;
		}
		&__description-subname {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}

.terms_header {
	&__position {
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}

.div_form_button {
	bottom: 0px;
	width: 100%;
	max-width: 770px;
	background: var(--color-white);
	padding-bottom: 10px;
	position: sticky;
	bottom: 0;
	@media (max-width: 420px) {
		padding: 10px;
	}
}

.react-flow__handle-left {
	margin-top: -6px;
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: transparent;
	border-radius: 50%;
	transform: scale(1.6);
	accent-color: var(--color-primary-light);
}

// second form
.kyb_form_container {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	max-width: 770px;
	gap: 16px;
	align-items: center;

	.input {
		height: 80px !important;
	}
}

.kyb_div_container {
	display: flex;
	gap: 16px;
	flex-direction: column;
	margin-top: 10px;

	&__entity {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;
		max-width: 770px;
		//overflow: auto;
		//margin-bottom: 80px;
	}

	&__button {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;
		max-width: 770px;
		padding-bottom: 6px;
		padding-top: 10px;
		position: sticky;
		margin: 0;
		@extend .div_form_button;
	}

	&__footer-text {
		display: flex;
		justify-content: center;
		padding: 16px 24px;
		gap: 8px;
		font-family: Poppins;
		font-style: normal;

		&--sub_text {
			color: var(--color-primary-light, #0051cc);
			cursor: pointer;
			font-weight: 500;
		}
	}
}

.terms-header {
	&__container {
		position: relative;
		overflow-y: hidden;

		@media (max-width: 505px) {
			overflow-y: overlay;
		}
	}

	&__wrapper {
		width: 100%;
		position: sticky;
		top: 0;
		z-index: 1111;
	}
}

.split-button-container {
	display: flex;
	justify-content: space-between;
}
//browse input css
// .button__small {
// 	padding: 0px;
// }

.browse_container {
	position: relative;

	&__button_container {
		position: absolute;
		right: 11px;
		top: 35px;
		bottom: 10px;
		border: 1px solid rgba(31, 31, 31, 0.12);
		padding: 2px 9px;
		border-radius: 4px;
	}

	&__label {
		color: #0051cc;
		font-weight: 500;
		padding-bottom: 17px;
	}
	.elpisis-wrapper {
		display: flex;
	}
	.elipsis__label {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 300px;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

// paras: css for file input
#browse_container__input {
	max-width: 75%;
}

.kyb_div_container__entity::-webkit-scrollbar {
	max-height: 10px;
	width: 8px;
	background: transparent;
}

.kyb_div_container__entity::-webkit-scrollbar-thumb {
	background: transparent;
}

.kyb_div_container__entity:hover::-webkit-scrollbar-thumb {
	background: #51545c;
	border-radius: 12px;
	margin-left: 10px;
}
