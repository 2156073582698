.fi-connect-bank {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	height: 100%;
	&__header {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 8px;
		gap: 4px;
		&__title {
			font-weight: 600;
			font-size: var(--font-size-5);
			line-height: calc(var(--line-height-2) * 1.75);
			color: var(--color-text-90-light);
			letter-spacing: 0;
		}
		&__sub-title {
			font-weight: 400;
			font-size: var(--font-size-2);
			line-height: 16px;
			color: var(--color-text-70-light);
		}
	}
	&__info {
		&__details {
			display: flex;
			align-items: center;
			gap: 8px;
			width: '100%';
			&__left {
				font-weight: 500;
				font-size: var(--font-size-3);
				color: var(--color-text-60-light);
			}
			&__right {
				font-weight: 600;
				font-size: var(--font-size-3);
				color: var(--color-text-80-light);
			}
		}
	}
	&__card {
		position: relative;
		display: grid;
		place-items: center;
		min-height: 200px;
		text-align: center;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		background: var(--color-bg-90-light);
		border-radius: calc(var(--border-radius) * 0.5);
		margin-top: calc(var(--margin) * 1.5);
		&__image {
			height: 250px;
			width: 100%;
		}
		&__doller_icon_wrapper {
			position: absolute;
			top: -20px;
			font-size: var(--font-size-7);
			width: 48px;
			height: 48px;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			border-radius: 50%;
			display: grid;
			place-items: center;
			color: var(--color-primary-light);
			background: var(--color-bg-100-light);
		}
		&__investment_wrapper {
			position: absolute;
		}
		&__amount_paid {
			font-size: calc(var(--font-size-7));
			font-weight: 600;
			color: var(--color-text-90-light);
			display: flex;
			flex-direction: column;
			height: fit-content;
			justify-content: space-between;
			gap: 2px;
		}
		&__amount_paid_text {
			font-weight: 500;
			font-size: 12px;
			color: var(--color-text-60-light);
		}
	}

	&__input {
		margin-bottom: 3%;
		.input__field {
			background: none !important;
			background-color: transparent !important;
			font-size: calc(var(--font-size-7));
			font-weight: 600;
			text-align: center;
			padding: 5px;
		}
		.input__text-field {
			border: 1px solid #000000;
			background: none !important;
			background-color: transparent !important;
			width: 95%;
			&--error {
				border-color: var(--color-error);
				background-color: hsla(0, 100%, 50%, 0.06);
			}
		}
		.input__group {
			justify-content: center;
		}
		&__amount_paid_text {
			font-weight: 500;
			font-size: 12px;
			color: var(--color-text-60-light);
			margin-top: 4px;
			line-height: 24px;
		}
	}
}

.bank-details {
	&__account-details {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 16px;
		&__lists {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;
		}
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			&__title {
				font-size: var(--font-size-4);
				font-weight: 600;
				color: #2f323d;
			}
			.add-bank-account {
				padding: 4px 8px;
				background: rgba(60, 101, 214, 0.06);
				border: 1px solid #3c65d6;
				border-radius: 24px;
				height: 24px;
				font-size: 12;
				font-weight: 500;
				color: #3c65d6;
				cursor: pointer;
			}
		}
	}
	&__left-header {
		display: flex;
		align-items: center;
	}
}

.single-payment {
	&__wrapper {
		display: flex;
		align-items: center;
		padding: 8px;
		gap: 4px;
		border-radius: 4px;
		border: 1px solid #dfe3ec;
		background-color: #f4f5f5;
	}
	&__icon {
		width: 48px;
		height: 48px;
		border-radius: 4px;
		font-size: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		color: #515767;
	}
	&__headiing {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
	}
	&__subHeading {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: #363a45;
	}
}

.bank-details {
	width: 100%;
	height: 100%;
	&__title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: var(--color-text-90-light);
	}
	&__sub_title {
		text-transform: capitalize;
		font-weight: 400;
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
		color: var(--color-text-60-light);
	}
	&__card_wrapper {
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
	}
	&__card_inner {
		display: flex;
		flex-direction: column;
		padding: calc(var(--padding) * 0.5);
		height: 100%;
		gap: calc(var(--margin) * 01.5286C13);
	}
	&__card_inner_bank {
		display: flex;
		// align-items: center;
		flex-direction: column;
		height: 100%;
		gap: 8px;
	}
	&__logo_wrapper {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: grid;
		place-items: center;
		font-weight: 600;
		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	&__card_number {
		font-size: 16px;
		color: var(--color-black);
		font-weight: 600;
	}
	&__amount_wrapper {
		display: flex;
		align-items: center;
		gap: calc(var(--margin) * 0.25);
	}
	&__avail_balance {
		color: var(--color-text-80-light);
		font-size: var(--font-size-3);
		font-weight: 600;
	}
	&__empty_bank_account {
		display: grid;
		place-items: center;
		height: 100;
		font-size: var(--font-size-3);
		min-height: 100px;
		width: 100%;
	}
	&__institution_name {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #747e99;
	}
	&__button_wrapper {
		margin-top: calc(var(--margin) * 0.5);
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
		width: 100%;
	}
	&__amount_paid_wrapper {
		position: relative;
		display: grid;
		place-items: center;
		min-height: 200px;
		text-align: center;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		background: var(--color-bg-90-light);
		border-radius: calc(var(--border-radius) * 0.5);
		margin-top: calc(var(--margin) * 1.5);
		&__image {
			height: 200px;
			width: 100%;
		}
		&__investment_wrapper {
			position: absolute;
		}
	}
	&__doller_icon_wrapper {
		position: absolute;
		top: -20px;
		font-size: var(--font-size-7);
		width: 48px;
		height: 48px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 50%;
		display: grid;
		place-items: center;
		color: var(--color-primary-light);
		background: var(--color-bg-100-light);
	}
	&__amount_paid {
		font-size: calc(var(--font-size-7));
		font-weight: 600;
		color: var(--color-text-90-light);
	}
	&__amount_paid_text {
		font-weight: 500;
		font-size: 12px;
		color: var(--color-text-60-light);
	}
	&__fund_details {
		display: flex;
		flex-direction: column;
		gap: 8px;
		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&__label {
				font-weight: 500;
				font-size: var(--font-size-3);
				color: var(--color-text-60-light);
			}
			&__value {
				font-weight: 600;
				font-size: var(--font-size-3);
				color: var(--color-text-80-light);
			}
		}
	}
}
.fund-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	width: 100%;
}

.fi-root-wrapper {
	padding-inline: 16px;
	height: 100%;
}

.fund-wrapper {
	max-width: 768px !important;
	width: 100% !important;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
}
.fund-investment {
	&__note {
		width: 100%;
		border: 2px dashed #f38a20;
		background-color: #e88a2c1f;
		border-radius: 4px;
		padding: 8px;
		gap: 4px;
		display: grid;

		&__icon {
			color: #f38a20;
			font-size: 24px;
			padding-right: 5px;
		}

		&__title {
			font-size: 16px;
			color: #1d1e21;
			line-height: 24px;
			display: flex;
			font-weight: 500;
			font-family: 'Poppins', sans-serif;
		}

		&__text {
			padding-left: 5px;
			font-weight: 400;
			color: #515767;
		}
	}
}
.fund--button {
	display: flex;
	gap: 8px;
	@media screen and (max-width: 768px) {
		position: fixed;
		bottom: 0;
		width: 100%;
		//TODO avinashSatschel need to add left0
		padding-right: 25px;
		background-color: var(--white);
		padding-block: 6px;
	}
}

@media (max-width: 480px) {
	.single-payment__wrapper {
		margin-bottom: 80px;
	}
}
