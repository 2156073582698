.payment-selector {
	display: flex;
	width: 100%;
	align-items: center;
	&__wrapper-height {
		height: 100%;
	}
	&__loader--wrapper {
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		color: var(--color-text-light-90);
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		gap: 24px;
		line-height: 24px;
	}
	&__right--section {
		width: 100%;
	}
	&__card-heading--wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__card--heading {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: var(--color-primary-light);
	}
	&__card--headarrow {
		font-size: 24px;
		font-weight: 500;
		line-height: 24px;
		color: var(--color-primary-light);
	}
	&__option--wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		width: 100%;
		cursor: pointer;
		&--disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
	}

	&__card {
		cursor: pointer;
		flex: 1 1 calc(50% - 8px);
		max-width: calc(50% - 8px);
		box-sizing: border-box;
		border: 1px solid #e2e8f0;
		border-radius: 4px;
		display: flex;
		width: 100%;
		padding: 12px 8px;
		gap: 8px;
	}
	&__card:only-child {
		flex: 1 1 100%;
		max-width: 100%;
	}

	&__icon {
		font-size: 24px;
		color: var(--color-primary-light);
	}

	&__label {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;
		color: #515767;
	}

	&__error {
		color: var(--color-delete);
		font-size: var(--font-size-2);
		margin-top: 0.5rem;
		text-align: center;
	}
}

@media (max-width: 480px) {
	.payment-selector__card {
		flex: 1 1 100%;
		flex-basis: 100%;
		max-width: 100%;
	}
	.payment-selector__option--wrapper {
		gap: 8px;
		margin-bottom: 40px;
		width: 100%;
	}

	.payment-selector__icon {
		font-size: 20px;
	}
	.payment-selector__label {
		font-size: var(--font-size-2);
	}
}

.sdk-screen {
	&__heading {
		margin-bottom: 20px;
	}
	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: calc(100% - 155px);
	}
	&__loader {
		position: absolute;
		width: 100px;
		left: 50%;
		top: 50%;
		right: 50%;
		transform: translate(-50%, -50%);
	}
	&__form {
		width: 100%;
	}
	&__back-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: calc(var(--padding) * 0.5) 0px;
		border: none;
		border-radius: 4px;
		font-size: var(--font-size-2);
		font-weight: 600;
		color: #333;
		background: none;
		cursor: pointer;
		transition:
			background-color 0.3s ease,
			transform 0.2s ease;
		i {
			font-size: var(--font-size-2);
			margin-right: 4px;
			transition: transform 0.3s ease;
		}
	}
}

.sdk-screen__back-button:disabled {
	color: var(--color-text-light-60);
	cursor: not-allowed;
	box-shadow: none;
}
.sdk-screen__form {
	color: var(--color-text-70-light);
	font-size: var(--font-size-3);
	font-weight: 500;
	line-height: var(--line-height-3);
}
